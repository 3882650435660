.lezzet-duraklari {
  margin-top: 14vh;

  & video {
    max-height: 86vh;
  }
  &-list {
    background-color: #0e1a33;
    background-image: url('/images/lezzet-duraklari-bg.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    min-height: 700px;
    padding-top: 65px;
    margin-top: -6px;
    margin-bottom: -53px;
    padding-bottom: 100px;
    h1 {
      font-size: 40px;
      color: #fff;
      margin-bottom: 15px;
    }
    .description {
      color: #fff;
      background: none !important;
      * {
        background: none !important;
      }
    }
    .filter {
      margin-top: 35px;
      display: flex;
      flex-direction: row;
      .item {
        position: relative;
        width: auto;
        input {
          background: #091428;
          border: 2px solid #11213d;
          border-radius: 15px;
          padding: 8px 42px;
          color: #fff;
        }
        span.c-icon {
          position: absolute;
          top: 6px;
          left: 28px;
          color: #fff;
          display: block;
          width: 25px;
          cursor: pointer;
        }
      }
    }
    .pager {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 10px;
      span {
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background: #091428;
        color: #fff;
        &.icon {
          background: none !important;
        }
        &.active {
          background: #c83631;
        }
      }
    }

    .items {
      .noRecordFound {
        font-size: 30px;
        color: #fff;
      }
      .item {
        display: flex;
        flex-direction: row;
        gap: 15px;
        margin-bottom: 25px;
        .image {
          min-width: 150px;
          max-width: 150px;
          max-height: 150px;
          min-height: 150px;
          img {
            border-radius: 25px;
          }
        }
        .right {
          color: #fff !important;
          padding-right: 20%;
          & > h5 {
            margin-bottom: 10px;
          }
          & > span {
          }
          .shortDescription {
            p {
              margin-bottom: 5px;
              margin-top: 0;
            }
          }
          a.goMap {
            display: block;
            margin-bottom: 5px;
            font-size: 12px;
            span {
              color: #c83631;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1024px) {
  .lezzet-duraklari {
    margin-top: 7vh;
    video {
      max-height: 93vh;
    }
  }
}

@media only screen and (max-width: 600px) {
  .lezzet-duraklari-list h1 {
    font-size: 24px;
    color: #fff;
    margin-bottom: 15px;
  }
  .lezzet-duraklari-list .items .item {
    flex-direction: column;
    .image {
      max-width: 150px;
    }
    .right {
      padding-right: 0;
    }
  }
}
